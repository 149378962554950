<template>
    <div class="container-fluid mb-80"  >
        <div class="row">
            <div class="col-md-12 ">
                <h4 class="title-in-h">{{$t('sidebar.configurations')}}</h4>
                <breadcrumb >
                    <breadcrumb-item  v-for="breadcrumb in breadcrumbs" v-if="breadcrumb.type!=='active'">
                        <router-link :to="{path:breadcrumb.path}" class="color-blue-var">{{breadcrumb.name}}</router-link>
                    </breadcrumb-item>
                    <breadcrumb-item active v-else>
                        {{breadcrumb.name}}
                    </breadcrumb-item>
                </breadcrumb>
            </div>
        </div>



        <div class="row">
            <vue-topprogress ref="topProgress"></vue-topprogress>

            <div class="col-12">
                <card>
                    <div>
                        <div class="col-sm-12">
                            <div class="box-info-configurations integrations">


                                <div class="box-item">
                                    <div class="row">

                                        <div class="col-sm-10">




                                            <div class="row">
                                                <div class="col-12">
                                                    <label class="title-config">{{this.$t('integration.title')}}</label>
                                                </div>
                                                <div class="offset-1 col-sm-5">
                                                    <label class="ul-item" :class="configuration.ratetiger_status?'checked':''">{{this.$t('integration.checkbox')}}</label>
                                                </div>
                                                <div class="col-sm-6">
                                                    <span class="fa fa-check item-checked"  :class="configuration.ratetiger_status?'checked':''" @click="configuration.ratetiger_status = !configuration.ratetiger_status; formsLogs('general')"></span>

                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="offset-1 col-3">
                                                     <span> {{this.$t('integration.hotel_id')}}:</span>
                                                </div>
                                                <div class="col-8 mar-top">
                                                    <fg-input type="text"
                                                              v-model="configuration.ratetiger_hotel_id"
                                                              @input="formsLogs('general')"
                                                              class="input-custom ipt-middle" :class="(error.ratetiger_hotel_id)?'inp-error':''"
                                                              :disabled="!configuration.ratetiger_status"
                                                    ></fg-input>
                                                </div>
                                            </div>


                                            <label v-if="error.ratetiger_general" class="lbl-subtitle lbl-error"> <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Completa el formulario para continuar. </label>
                                        </div>
                                        <div class="col-sm-2">
                                            <button
                                              v-if="writting_permission" :disabled="!forms_log.general"
                                              type="button" class="btn btn-inactive btn-fill btn-wd" :class="forms_log.general?'btn-success':''" @click="checkForm('general')">
                                                {{$t('form.update')}}
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div class="box-item">
                                    <div class="row">
                                        <div class="col-sm-10">
                                            <label class="title-config mb-2">{{ this.$t('integration.room.title')}}</label>





                                            <div class="row">
                                                <div class="offset-1 col-5">
                                                    <label>{{ this.$t('integration.rateplan.name')}}</label>
                                                </div>

                                                <div class="col-3">
                                                    <label>{{ this.$t('integration.rateplan.code')}}</label>
                                                </div>
                                                <div class="col-3">
                                                    <label>{{ this.$t('integration.rateplan.code_ratetiger')}}</label>
                                                </div>
                                            </div>


                                            <div class="row" v-for="(element, idx) in ratetiger_rooms">
                                                <div class="col-1">
                                                    <a @click="deleteRowRoom(idx)">
                                                        <i class="fa fa-trash"></i>
                                                    </a>
                                                </div>
                                                <div class="col-5">
                                                    <fg-input v-model="element._id"
                                                              :name="$t('contract.form.add_rooms')"  :class="error.ratetiger_room && element._id===null?'inp-error':''">
                                                        <el-select class="select-default" size="large"  :name="$t('contract.form.type')"
                                                                   v-model="element._id" @change="updateInfoRoom(element._id)">
                                                            <el-option v-for="(option, idx) in info_page.rooms" class="select-default" :value="option._id"
                                                                       v-show="option.showme===true" :label="option.name" :key="idx">
                                                            </el-option>
                                                        </el-select>
                                                    </fg-input>



                                                </div>

                                                <div class="col-3">
                                                    <fg-input
                                                            :name="$t('contract.form.type')"
                                                            v-model="element.cod"
                                                            disabled="true">
                                                    </fg-input>
                                                </div>
                                                <div class="col-3">
                                                    <fg-input
                                                            @input="formsLogs('room')"
                                                            :name="$t('contract.form.type')"
                                                            v-model="element.ratetiger_cod" :class="error.ratetiger_room && (element.ratetiger_cod===null || element.ratetiger_cod.trim().length===0 || (element.ratetiger_dup!== undefined && element.ratetiger_dup))?'inp-error':''">
                                                    </fg-input>
                                                </div>
                                            </div>



                                            <div class="row group-item">
                                                <div class="col-12">
                                                     <span class="btn-link mt-4"
                                                           @click="addElement('room')"  v-show="info_page.rooms.length> ratetiger_rooms.length && property.ratetiger_status" >
                                                     {{$t('integration.room.add_element')}}
                                                 </span>

                                                    <label v-if="!property.ratetiger_status" class="lbl-subtitle"><i class="fa fa-exclamation-circle" aria-hidden="true" ></i> Para realizar cambios es necesario activar la conexión a ratetiger. </label>

                                                    <label v-if="error.ratetiger_room" class="lbl-subtitle lbl-error complete-line"> <i class="fa fa-exclamation-circle" aria-hidden="true"></i> {{error.room_msg}} </label>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-sm-2 d-flex">
                                            <button
                                                    v-if="writting_permission" :disabled="!((forms_log.room && property.ratetiger_status)===true)"
                                                    type="button" class="btn btn-inactive btn-fill btn-wd" :class="((forms_log.room && property.ratetiger_status)===true)?'btn-success':''" @click="checkForm('room')">
                                                {{$t('form.update')}}
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div class="box-item">
                                    <div class="row">
                                        <div class="col-sm-10">
                                            <label class="title-config mb-2">{{ this.$t('integration.rateplan.title')}}</label>





                                            <div class="row">
                                                <div class="offset-1 col-5">
                                                    <label>{{ this.$t('integration.rateplan.name')}}</label>
                                                </div>

                                                <div class="col-3">
                                                    <label>{{ this.$t('integration.rateplan.code')}}</label>
                                                </div>
                                                <div class="col-3">
                                                    <label>{{ this.$t('integration.rateplan.code_ratetiger')}}</label>
                                                </div>
                                            </div>


                                            <div class="row" v-for="(element, idx) in ratetiger_rateplans">
                                                <div class="col-1">
                                                    <a @click="deleteRow(idx)">
                                                        <i class="fa fa-trash"></i>
                                                    </a>
                                                </div>
                                                <div class="col-5">
                                                    <fg-input v-model="element._id"
                                                              :name="$t('contract.form.add_rooms')">
                                                        <el-select class="select-default" size="large"  :name="$t('contract.form.type')"
                                                                   v-model="element._id" @change="updateInfo(element._id)">
                                                            <el-option v-for="(option, idx) in info_page.rateplans" class="select-default" :value="option._id"
                                                                       v-show="option.showme===true" :label="option.name" :key="idx">
                                                            </el-option>
                                                        </el-select>
                                                    </fg-input>



                                                </div>

                                                <div class="col-3">
                                                    <fg-input
                                                            :name="$t('contract.form.type')"
                                                            v-model="element.cod"
                                                            disabled="true">
                                                    </fg-input>
                                                </div>
                                                <div class="col-3">
                                                    <fg-input
                                                            @input="formsLogs('rateplan')"
                                                             :name="$t('contract.form.type')"
                                                            v-model="element.ratetiger_cod" :class="error.ratetiger_rateplan && (element.ratetiger_cod===null || element.ratetiger_cod.trim().length===0 || (element.ratetiger_dup!== undefined && element.ratetiger_dup) )?'inp-error':''">

                                                    </fg-input>
                                                </div>
                                            </div>



                                            <div class="row group-item">
                                                <div class="col-12">
                                                     <span class="btn-link mt-4"
                                                           @click="addElement('rateplan')"  v-show="info_page.rateplans.length> ratetiger_rateplans.length && property.ratetiger_status && info_page.hasRoomsActivated">
                                                     {{$t('integration.rateplan.add_element')}}
                                                 </span>

                                                    <label class="lbl-subtitle" v-if="!property.ratetiger_status && !info_page.hasRoomsActivated">
                                                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                                        Para realizar cambios es necesario activar la conexión a ratetiger
                                                        <span v-show="!info_page.hasRoomsActivated">y habitaciones conectadas.</span>
                                                    </label>
                                                    <label class="lbl-subtitle" v-if="property.ratetiger_status && !info_page.hasRoomsActivated">
                                                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                                        Para realizar cambios es necesario tener habitaciones conectadas.
                                                    </label>
                                                  <label v-if="error.ratetiger_rateplan" class="lbl-subtitle lbl-error complete-line"> <i class="fa fa-exclamation-circle" aria-hidden="true"></i> {{error.rateplan_msg}} </label>


                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-sm-2 d-flex">
                                            <button
                                            v-if="writting_permission" :disabled="!((forms_log.rateplan && property.ratetiger_status && ratetiger_rooms.length>0 && info_page.hasRoomsActivated)===true)"
                                            type="button" class="btn btn-inactive btn-fill btn-wd" :class="((forms_log.rateplan && property.ratetiger_status && ratetiger_rooms.length>0 && info_page.hasRoomsActivated)===true)?'btn-success':''" @click="checkForm('rateplan')">
                                                {{$t('form.update')}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </card>
            </div>
        </div>
    </div>

</template>
<script>
    import swal from 'sweetalert2'
    import {Breadcrumb, BreadcrumbItem, Switch as LSwitch} from 'src/components/index'
    import PropertyService from '../../../js/services/PropertyService';
    import {Select, Option, Tag,  Loading, Button} from 'element-ui'
    import EventBus from "../../../js/helpers/EventBus";
    import { vueTopprogress } from 'vue-top-progress'
    import ContractService from '../../../js/services/ContractService';
    const rateplanService = new ContractService();
    import RoomService from '../../../js/services/RoomService';
    const roomService = new RoomService();

    import LogFunctions from '../../../js/functions/LogFunctions';
    import LogService from '../../../js/services/LogService';
    const propertyService = new PropertyService();
    import { mapGetters } from "vuex";
    import moment from 'moment';
    const logFunctions = new LogFunctions();
    const logService = new LogService();


    export default {
        components: {
            Breadcrumb,
            LSwitch,
            [Option.name]: Option,
            [Select.name]: Select,
            BreadcrumbItem,
            [Tag.name]: Tag,
            [Button.name]: Button,
            vueTopprogress
        },
        computed: {
          ...mapGetters(["currentUser","selected_property","writting_permission"]),

        },
        data() {
            return {
                log_page:{
                    action: null,
                    section: null,
                    property_id: null,
                    data: [],
                    date: new Date(),
                    object_id: null,
                    user_id: null,
                },
                fullscreenLoading: false,
                loading: false,
                options: [],
                breadcrumbs:[
                    {
                        name: this.$t('property.index.home'),
                        path: '/admin/properties',
                        type: '',
                    },
                    {
                        name: this.$t('property.index.property'),
                        path: '/admin/properties',
                        type: '',
                    },

                    {
                        name: this.$t('sidebar.configurations'),
                        path: '',
                        type: 'active',
                    }
                ],
                info_page:{
                    original_info:{},
                    section_name: 'property',
                    property_id: {},
                    tab_lang: 'es',
                    langs:[{
                        name: this.$t('locale_es'),
                        locale: 'es'
                    },{
                        name: this.$t('locale_en'),
                        locale: 'en'
                    },],
                    rateplans: [],
                    rooms: [],
                    hasRoomsActivated: false
                },
                baseRow:{
                    _id:null,
                    ratetiger_cod:null,
                    cod:null,
                    ratetiger_connect: false,
                    cancellation_policy: null

                },


                ratetiger_rateplans: [],
                ratetiger_rateplans_deleted: [],
                ratetiger_rooms: [],
                ratetiger_rooms_deleted: [],


                configuration:{
                    ratetiger_hotel_id: null,
                    ratetiger_status: false
                },
                forms_log:{
                    general:    false,
                    room:       false,
                    rateplan:     false
                },
                error:{
                    ratetiger_hotel_id: false,
                    ratetiger_status:   false,
                    ratetiger_general: false,
                    ratetiger_room: false,
                    ratetiger_rateplan: false,
                    room_msg: 'Completa el formulario para continuar.',
                    rateplan_msg: 'Completa el formulario para continuar.',
                },
                property: {
                    _id: '',
                    status: false,
                },
                section: 'PROPERTY',




            }
        },
        created(){
            this.chargerItems(this.selected_property._id);
            this.$store.dispatch('CHANGE_SECTION',this.section);
        },
        mounted() {
            //this.$refs.topProgress.start();
            this.initLoading();
            this.changeProperty()
            /*this.list = getTimeZones().map(item => {
                //console.log('list');
                return { value: item.name, name: item.currentTimeFormat};
            });*/
        },
        methods: {
            changeProperty(){
                EventBus.$on('CHANGE_PROPERTY', (property_id) => {
                    if(this.$route.name==='configurationProperty'){
                        //console.log(this.$route.name, 'rooms')
                        //this.$refs.topProgress.start();
                        this.initLoading();
                        this.chargerItems(property_id);
                    }
                })
            },
            configLogs(property_id, section_name){
                this.log_page.section = logFunctions.findSection(section_name)._id
                this.log_page.property_id = property_id;
                this.log_page.user_id = this.currentUser._id;
                this.log_page.action = 1;
                this.log_page.date = moment().format('YYYY-MM-DD HH:mm');
            },
            async createLog(object_id, action, data = []){
                let paramsLog= this.log_page;
                paramsLog.object_id = object_id;
                //paramsLog.section = logFunctions.findSection(this.info_page.section_name)._id
                paramsLog.action = action;
                paramsLog.data = data;
                paramsLog.date = moment().format('YYYY-MM-DD HH:mm:ss');
                await logService.create(paramsLog);
            },
            async chargerItems(property_id){
                this.info_page.property_id = property_id;


                this.forms_log.general  = false;
                this.forms_log.room = false;
                this.forms_log.rateplan  = false;

                this.configLogs(property_id, this.info_page.section_name);



                await this.getInfoRateplans(property_id)
                await this.getInfoRooms(property_id)
                await this.getInfoProperty(property_id)



                this.closeLoading();
            },
            async getInfoProperty(property_id){



                propertyService.getPropertyId(property_id).then(response => {
                    let data = response.data.data.property;

                  this.property = data;
                    this.configuration = {
                        ratetiger_hotel_id: data.ratetiger_hotel_id,
                        ratetiger_status: data.ratetiger_status
                    }
                })
            },

            async getInfoRateplans(property_id){
                let  params = {
                    property_id: property_id,
                    status: true,
                    expired: false
                }
                let rateplans =  await rateplanService.getRateplanIndex(params);
                rateplans = rateplans.data.data.rateplans;

                //rateplans.map(item => item.update = false)
                rateplans.map(item => item.ratetiger_connect = (item.ratetiger_connect!==undefined)?item.ratetiger_connect:false)

                this.info_page.rateplans = rateplans;
                rateplans.map(item => item.showme = !item.ratetiger_connect)

                this.ratetiger_rateplans = JSON.parse(JSON.stringify(rateplans.filter(item=> item.ratetiger_connect)))
            },

            async getInfoRooms(property_id){
                let params = {
                    property: property_id,
                    status: true
                }

                let responseRequest =  await roomService.getRoomsList(params)
                responseRequest = responseRequest.data.data.rooms;
                responseRequest.map(item => item.ratetiger_connect = (item.ratetiger_connect!==undefined)?item.ratetiger_connect:false)
                this.info_page.rooms = responseRequest;
                responseRequest.map(item => item.showme = !item.ratetiger_connect)
                this.ratetiger_rooms = JSON.parse(JSON.stringify(responseRequest.filter(item=> item.ratetiger_connect)))

                this.info_page.hasRoomsActivated = responseRequest.some(item=> item.ratetiger_connect===true);

            },


            handleEdit(id, section) {
                this.$router.push({
                    name: section,
                    params: {
                        id: id
                    }
                })
            },

            addElement(section) {

                if(section==='rateplan'){
                    this.ratetiger_rateplans.push({...this.baseRow})
                }
                if(section==='room'){
                    let infoRow = {...this.baseRow};
                    delete infoRow.cancellation_policy;
                    this.ratetiger_rooms.push(infoRow)
                }

                this.formsLogs(section)
            },
            deleteRow(idxOf){
                let infoRateplan = this.ratetiger_rateplans[idxOf]

                if(infoRateplan._id!==null){
                    let resultRateplan= this.info_page.rateplans.find(item=> item._id === infoRateplan._id);

                    if(this.ratetiger_rateplans_deleted.indexOf(resultRateplan._id)<0){
                        this.ratetiger_rateplans_deleted.push(resultRateplan._id)
                    }
                    resultRateplan.showme = true;
                }
                this.formsLogs('rateplan')
                this.ratetiger_rateplans.splice(idxOf, 1)

            },
            deleteRowRoom(idxOf){
                let infoRoom = this.ratetiger_rooms[idxOf]


                if(infoRoom._id!==null){
                    let resultRoom= this.info_page.rooms.find(item=> item._id === infoRoom._id);

                    if(this.ratetiger_rooms_deleted.indexOf(infoRoom._id)<0){
                        this.ratetiger_rooms_deleted.push(infoRoom._id)
                    }
                    resultRoom.showme = true;
                }


                this.formsLogs('room')
                this.ratetiger_rooms.splice(idxOf, 1)

            },

            updateInfo(_id){

                let resultInfo = this.ratetiger_rateplans.find(item=> item._id === _id);
                let resultRateplan= this.info_page.rateplans.find(item=> item._id === _id);

                let idxDLT = this.ratetiger_rateplans_deleted.indexOf(resultRateplan._id)
                if(idxDLT>=0){
                    this.ratetiger_rateplans_deleted.splice(idxDLT, 1)
                }
                resultInfo.cod = resultRateplan.cod;

                resultInfo.ratetiger_cod = (resultRateplan.ratetiger_cod!==undefined)?resultRateplan.ratetiger_cod:null;
                //resultInfo.ratetiger_cod = resultRateplan.ratetiger_cod;
                resultInfo.ratetiger_connect = true;
                resultInfo.cancellation_policy = resultRateplan.cancellation_policy;
                //resultRateplan.update = true;
                resultRateplan.showme = false;
                this.formsLogs('rateplan')
                this.checkDifferences('rateplan')
            },
            updateInfoRoom(_id){

                let resultInfo = this.ratetiger_rooms.find(item=> item._id === _id);
                let resultRoom = this.info_page.rooms.find(item=> item._id === _id);

                let idxDLT = this.ratetiger_rooms_deleted.indexOf(resultRoom._id)
                if(idxDLT>=0){
                    this.ratetiger_rooms_deleted.splice(idxDLT, 1)
                }
                resultInfo.cod = resultRoom.cod;
                resultInfo.ratetiger_cod = (resultRoom.ratetiger_cod!==undefined)?resultRoom.ratetiger_cod:null;
                resultInfo.ratetiger_connect = true;
                resultRoom.showme = false;

                this.formsLogs('room')
                this.checkDifferences('room')
            },

            async sendFormInfo(){
                //let rateplansDeleted = this.ratetiger_rateplans_deleted;
                let rateplansUpdated= this.ratetiger_rateplans;
                let errorReturn = false;

                let originalRateplans = this.info_page.rateplans.filter(item => item.ratetiger_connect===true);

              let rateplansDeleted = this.getDifference(originalRateplans, rateplansUpdated);
              await this.asyncForEach(rateplansDeleted, async resultRateplan=>{
                    let params = {
                        _id: resultRateplan._id,
                        ratetiger_connect: false,
                        ratetiger_cod: null,
                        cancellation_policy: resultRateplan.cancellation_policy,
                    }
                    await rateplanService.updateContractRatetiger(params).catch(error=>{
                      errorReturn = true;
                    })
                })
              await this.asyncForEach(rateplansUpdated, async rateplan=>{
                rateplan.ratetiger_dup = false;
                    let params = {
                        _id: rateplan._id,
                        ratetiger_connect: rateplan.ratetiger_connect,
                        ratetiger_cod: rateplan.ratetiger_cod,
                        cancellation_policy: rateplan.cancellation_policy,
                    }
                    await rateplanService.updateContractRatetiger(params).catch(error=>{
                      this.error.rateplan_msg = error.response.data.err;
                      errorReturn = true;
                      rateplan.ratetiger_dup = true;
                    })
                })

              await this.closeLoading();
              if(errorReturn){
                this.error.ratetiger_rateplan = true;
                this.alertError('rateplan')
              }else{
                this.showSwal(this.$t('property.config.msg'));
              }
            },


          getDifference(array1, array2) {
              return array1.filter(object1 => {
                return !array2.some(object2 => {
                  return object1._id === object2._id;
                });
              });
            },
            sendFormGeneral(){

                let data = this.configuration;

                propertyService.updateIntegrationProperty(this.info_page.property_id, data)
                .then(async (response) => {
                    /*let propertyInfo = response.data.data.property;
                    let changes = await logFunctions.cheackProp(this.info_page.original_info, propertyInfo, this.info_page.section_name)
                    if(changes.length){
                        await this.createLog(propertyInfo._id, 1, changes);
                    }*/
                    this.$store.dispatch('UPDATE_PROPERTY', response.data.data.property);
                    await this.closeLoading();
                    this.showSwal(this.$t('property.config.msg'));

                })
            },
            async sendFormInfoRoom(){


              let originalRooms = this.info_page.rooms.filter(item => item.ratetiger_connect===true);
                let roomsUpdated= this.ratetiger_rooms;
              let roomsDeleted = this.getDifference(originalRooms, roomsUpdated);
              let errorReturn = false;


              await this.asyncForEach(roomsDeleted, async element=>{
                    let params = {
                        _id: element._id,
                        ratetiger_connect: false,
                      ratetiger_cod: null,
                    }
                    await roomService.updateRoomRatetiger(params).catch(error=>{
                      errorReturn = true;
                      element.ratetiger_dup = true;
                    })

                })
              await this.asyncForEach(roomsUpdated, async room=>{
                  room.ratetiger_dup = false;

                  let params = {
                        _id: room._id,
                        ratetiger_connect: room.ratetiger_connect,
                        ratetiger_cod: room.ratetiger_cod,
                    }
                    await roomService.updateRoomRatetiger(params).catch(error=>{
                      this.error.room_msg = error.response.data.err;
                      errorReturn = true;
                      room.ratetiger_dup = true;
                    })

                })
              await this.closeLoading();
              if(errorReturn){
                this.error.ratetiger_room = true;
                this.alertError('room')
              }else{
                this.showSwal(this.$t('property.config.msg'));
              }
            },

            checkDifferences(typeElem){
                //console.log('this.revenue', this.revenue);
                if(typeElem==='room'){
                    let objectCompare = this.ratetiger_rooms;
                    this.info_page.rooms.map(item =>  item.showme = !(objectCompare.some(objectItem => objectItem._id === item._id)));
                }
                if(typeElem==='rateplan'){
                    let objectCompare = this.ratetiger_rateplans;
                    this.info_page.rateplans.map(item =>  item.showme = !(objectCompare.some(objectItem => objectItem._id === item._id)));
                    console.log('si entra')
                }

            },


             async asyncForEach (array, callback) {
                for (let index = 0; index < array.length; index++) {
                    await callback(array[index], index, array);
                }
            },



            formsLogs(url){
                //this.$store.dispatch('CHANGES_ACTIVES', true);
                if(url==='general'){
                    this.forms_log.general = true
                }
                if(url==='rateplan'){
                    this.forms_log.rateplan = true
                }
                if(url==='room'){
                    this.forms_log.room = true
                }
            },



            /******************* Post de formularios **************************************/
            checkForm(option){
                let datas = {};
                let errors = 0;
                switch (option) {
                    case 'general':
                        datas  = this.configuration;
                        this.error.ratetiger_general = false;
                        for (let prop in datas) {
                            this.error[prop] = false;
                        }
                        if(datas.ratetiger_status){
                            for (let prop in datas) {
                                if(prop!=='ratetiger_status'){

                                    if(this.configuration[prop].trim().length === 0){
                                        this.error[prop] = true;
                                        errors++;
                                    }else{
                                        datas[prop] = this.configuration[prop];
                                    }
                                }
                            }
                        }
                        this.error.ratetiger_general = (errors>0)
                        break;
                    case 'room':

                        this.error.room = false;
                        this.ratetiger_rooms.forEach(element=>{
                            if(element.ratetiger_cod===null || element.ratetiger_cod.trim().length === 0 || element._id===null){
                                errors++;
                            }
                        })
                        this.error.room_msg = 'Completa el formulario para continuar.';
                        this.error.ratetiger_room = (errors>0)
                        break;
                    case 'rateplan':
                        this.error.rateplan = false;
                        this.ratetiger_rateplans.forEach(element=>{
                            if(element.ratetiger_cod.trim().length === 0 || element._id===null){
                                errors++;
                            }
                        })
                        this.error.ratetiger_rateplan = (errors>0)
                      this.error.rateplan_msg = 'Completa el formulario para continuar.';

                        break;
                }
                if(errors===0){
                    this.sendForm(option)
                }
            },


            compareObjects(object1, object2, key, orderIs = 'ascending') {
                const obj1 = object1[key].toUpperCase()
                const obj2 = object2[key].toUpperCase()
                let comparison = 0;

                if (obj1 < obj2) {
                    comparison = -1
                }
                if (obj1 > obj2) {
                    comparison =  1
                }

                if(orderIs==='descending'){
                    return comparison * -1
                }

                return comparison

            },

            async sendForm(option){
                this.initLoading('Guardando');

                if(option==='general'){
                    this.sendFormGeneral();
                }
                if(option==='room'){
                    this.sendFormInfoRoom();
                }
                if(option==='rateplan'){
                    this.sendFormInfo();
                }

                /*await this.closeLoading();
                this.showSwal(this.$t('property.config.msg'));*/

            },
            showSwal (msg) {

                swal({
                    title: this.$t('property.new.modal'),
                    text: msg,
                    buttonsStyling: false,
                    confirmButtonClass: 'btn btn-success btn-fill',
                    type: 'success'
                }).then(()=> {

                    this.chargerItems(this.selected_property._id);


                   /* this.$router.push({
                        name: 'Contracts'
                    });*/
                });
            },



            alertError(type){
                console.log('alertError', type)
                let htmlList= '<ul class="ml-3" style="text-align: left">'
                let option = '';




                if(type==='rateplan'){
                  option += 'Código de contrato duplicado'
                }
              if(type==='room'){
                option += 'Código de habitación duplicado'
                }


                htmlList+= '</ul>'


                let htmlResponse = '<p>'+option+'</p>';

                //htmlResponse += htmlList;

                swal({
                    title: 'Acción cancelada',
                    type: 'warning',
                    html:htmlResponse,
                    confirmButtonText: 'Aceptar',
                    buttonsStyling: false,
                    confirmButtonClass: 'btn btn-success btn-fill'
                })

            },


            /*******************************Funciones para seccion pagos*******************************/


            findIndex(arr, value) {
                let obj = arr.find(o => o.value === value);
                return  arr.indexOf(obj);
            },







            initLoading(text = 'Cargando...') {
                if(text==='Cargando...'){
                    this.$store.dispatch('CHANGES_ACTIVES', false);
                    this.records = [];
                }
                const loading = Loading.service({
                    lock: true,
                    text: text,
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.fullscreenLoading = loading;
            },
            closeLoading(){
                this.fullscreenLoading.close();
            },



        }
    }
</script>
<style lang="scss">
    .full-w{
        width: 100%;
    }
    .ln-1m{
        line-height: 35px;
    }
    .cursor-undrop{
        cursor: no-drop;
    }
    .lbl-error{
        color: #fb404b !important;
        text-transform: none !important;
        font-size: 12px;
        &.complete-line{
            width: 100%;
            display: inline-block;
        }
    }




    .box-colors{
        .ul-item.checked{
            line-height: 30px;
        }
        .bx-color {
            width: auto;
            display: inline-block;
            border: 1px solid #E3E3E3;
            margin-bottom: 0.5rem;
            background: #ebebeb;
            border-radius: 5px;
            input{
                background: #fff;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                border: 0;
                width: 100px;
                position: relative;
                top: -1px;
                padding-left: 7px;
            }
        }
        .color-picker-component{
            .current-color {
                display: inline-block;
                width: 20px;
                height: 20px;
                background-color: #000;
                cursor: pointer;
                margin: auto 3px;
                top: 3px;
                position: relative;
                border-radius: 15px;
            }
            .ipt-border {
                width: 100px;
                border: 0;
                margin: 0;
                border-right: 2px solid;
                float: left;
                display: inline-block;
                height: 28px;
            }
        }

    }


    .itm-ctrl{
        height: 40px;
        display: inline-block;
        i{
            text-align: center;
            line-height: 40px;
            margin-left: 10px;
        }
    }



    .color-picker-component{

        .picker {
            position: absolute;
            left: 10px;
            top: 30px;
            z-index: 10;
            margin-bottom: 50px;
        }
    }
    .bx-color{
        width: auto;
        display: inline-block;
    }
    .mb-80{
        margin-bottom: 130px;
    }
    .lbl-70{
        display: inline-block;
        width: 70px;
    }


    .box-info-configurations{
        &.integrations{
            .inp-error input{
                border-color: #fb404b !important;
            }
            span{
                line-height: 40px;
            }
            .ul-item{
                line-height: 40px;
                padding-left: 0;
            }
        }
        border: 0;
        padding: 40px 0;
        display: inline-block;

        .select-label{
            width: 28px;
            font-weight: 600;
            font-size: 16px;
            text-align: right;
            padding-right: 10px;
        }
        .mr-15px{
            margin-right: 15px;
        }
        .box-item {
            padding: 15px;
            border: 2px solid #eeeeee;
            overflow: hidden;
            float: left;
            margin: 0;
            width: 100%;
            margin-bottom: 15px;
            &.show{
                overflow: visible;
            }
            .text-alert{
                text-transform: none;
                margin-left: 30px;
                margin-bottom: 10px;
                color: goldenrod;
                font-size: 15px;
            }
            .itm-payment{
                width: 90%;
                padding-left: 5%;
                display: inline-block;
                .form-group{
                    width: 80%;
                    float: left;
                }
                .item-control{
                    width: 20%;
                    float: left;
                    line-height: 37px;
                    text-align: center;
                }
            }
            .box-ages{
                .form-group{
                    width: 42%;
                    display: inline-block;
                }
            }
        }
        .title-config{
            text-transform: none;
            display: inline-block;
            font-size: 20px;
            color: #000000;
            line-height: 37px;
            margin-bottom: 0;
        }
        .d-flex{
            display: flex;
        }
        .btn-success, .btn-inactive{
            min-width: 100%;
            vertical-align: middle;
            margin: auto;
            height: 37px;
            text-align: center;
        }
        .ipt-border{
            width: 100px;
            border: 2px solid;
            margin: 0 10px;


            &.double{
                width: 200px;

            }
            &.inp-size{
                padding: 0 7px;
                width: 300px;
            }
        }
        .mar-top{
            padding-top: 4px;
        }
        .ul-item{
            padding-left: 15px;
            text-transform: none;
            font-size: 15px;
            &.checked{
                color: #000000;
            }

        }
        .item-checked{
            color: #E1D8E9;
            &.checked{
                color: #1FBF64;
            }
        }
        .item-payment{
            width: 100%;
            display: inline-block;
            .lbl-error{
                margin-bottom: 5px;
                display: inline-block;
            }
            input{
                font-size: 11px;
                width: 100%;
                margin: 0 0 7px;
                padding: 0 7px;
                height: 25px;
                &.inp-error{
                    margin-bottom: 0;
                }
            }

        }
    }
    .input-custom{
        display: inline-block;
        &.ipt-small {
            width: 100px;
        }
        &.ipt-middle{
            width: 350px;
        }
    }
    .el-tag + .el-tag {
        margin-left: 10px;

    }
    .el-tag {
        background-color: #6b3e914d;
        border-color: #6b3e914d;
        color: #6B3E91;
        .el-tag__close:hover {
            background:  transparent;
            color: #6B3E91;
        }

    }
    .button-new-tag {
        margin-left: 10px;
        height: 32px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }
    .input-new-tag {
        input{
            width: 150px;
            padding-left: 5px;
            padding-right: 5px;

        }
        margin-left: 10px;
        vertical-align: top;
    }

</style>
