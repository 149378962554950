import { render, staticRenderFns } from "./ratetiger.vue?vue&type=template&id=b6e430fc&"
import script from "./ratetiger.vue?vue&type=script&lang=js&"
export * from "./ratetiger.vue?vue&type=script&lang=js&"
import style0 from "./ratetiger.vue?vue&type=style&index=0&id=b6e430fc&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports